import { render } from "./index.vue?vue&type=template&id=3731e4d3&scoped=true"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"

import "./index.vue?vue&type=style&index=0&id=3731e4d3&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-3731e4d3"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3731e4d3"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3731e4d3', script)) {
    api.reload('3731e4d3', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=3731e4d3&scoped=true", () => {
    api.rerender('3731e4d3', render)
  })

}

script.__file = "src/views/villageInfo/index.vue"

export default script